import { H } from 'highlight.run'

H.init(process.env.HIGHLIGHT_PROJECT_ID, {
  environment: 'production',
  enableStrictPrivacy: false,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
  manualStart: true,
})
